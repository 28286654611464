import logo from './logo.svg';
import './App.css';
import Title from './Title';


function App() {
  return (
    <div className="App">
      <head>
      <Title />
      </head>
      <header className="App-header">
        
        <img src={logo} className="App-logo" alt="logo" />
        <p>Testing</p>
        <p>Learn Cybersecurity</p>
      </header>
    </div>
  );
}

export default App;
